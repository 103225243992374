/**
 * Function for hamburger menu, don't remove
 */
function burgerCollapse(){
  const navbarCollapse = $('.navbar-collapse');
  const navbar = $('.navbar');
  const toggler = $('.navbar-toggler');
  
  // Add classes to navbar and toggler button on hide / show mobile menu
  navbarCollapse.on('show.bs.collapse hide.bs.collapse', function (e) {
    if (e.type === 'show') {
      navbar.addClass('is-open');
      toggler.addClass('is-active');
    }
    if (e.type === 'hide') {
      navbar.removeClass('is-open');
      toggler.removeClass('is-active');
    }
  });
}

/**
 * Function to "remove" scroll when mobile menu is expanded
 */
function overflowMobileMenu(){
  if ($(window).width() < 1200) {
    $('.navbar-toggler.hamburger').on('click',function() {
      if ($(this).hasClass('is-active')) {
        $('body').css('overflow', 'auto');
      } else {
        $('body').css('overflow', 'hidden');
      };
    });
  } else {
    $('body').css('overflow', 'auto');
  }
}

$(function(){
  burgerCollapse();
  overflowMobileMenu();
  $(window).on('resize', function() {
    overflowMobileMenu();
  });
});