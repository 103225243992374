/**
 * Function for the card in planning page
 */
function displayCoursesByDiscipline(){
  $('.courses-by-discipline .cards-wrapper--item').on('click', function(){
    $(this).find('.schedule-wrapper').slideToggle();
  });
}

$(function(){
  displayCoursesByDiscipline();
});